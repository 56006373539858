<template>
	<div class="min-h-screen min-w-screen overflow-hidden">
		<!-- ------------------------------------------------- -->
		<!-- NAVBAR -->
		<!-- ------------------------------------------------- -->
		<div
			class="rysqer-layout--navigationbar-height flex justiy-between gap-6 w-full border-b"
			v-if="rysqer.auth.authenticated"
		>
			<div class="flex-1 flex items-center px-3 gap-6">
				<router-link :to="{ name: 'home' }" class="flex-none self-center">
					<img src="@/assets/images/rysqer.svg" class="w-28" />
				</router-link>

				<div class="d-tabs" v-if="rysqer.active.app?.showTabs">
					<a
						class="d-tab gap-2"
						:class="{ 'd-tab-active text-primary': view.id == rysqer.active.view.id }"
						v-for="view in Object.values(rysqer.active.app?.views).filter((v) => {
							return v.condition == null || v.condition == true
						})"
						:key="view.id"
						@click="rysqer.router.push({ name: view.route }).catch(() => {})"
					>
						{{ view.label }}
						<span class="d-badge d-badge-secondary d-badge-sm" v-if="view.indicator">{{ view.indicator }}</span>
					</a>
				</div>

				<portal-target name="navbar"></portal-target>
			</div>
			<div class="flex-none px-3 flex items-center gap-4" v-if="rysqer.auth.authenticated">
				<tippy trigger="click" :hideOnClick="true" interactive>
					<template #trigger>
						<button class="flex items-center gap-1">
							<span class="font-bold">{{ rysqer.auth.user.name }}</span>
							<icon icon="chevron-down" cls="h-4 w-4" />
						</button>
					</template>
					<div class="p-4 space-y-3 min-w-sm flex justify-between items-center gap-8">
						<div class="space-y-1">
							<p class="text-lg font-bold">{{ rysqer.auth.user.name }}</p>
							<p class="text-sm font-medium">{{ rysqer.auth.user.title }}</p>
							<p class="text-sm">{{ rysqer.auth.user.roles.join(', ') }}</p>
							<!-- <p class="text-sm font-medium">{{ rysqer.auth.user.tenant.name }}</p> -->
						</div>

						<button
							class="d-btn d-btn-sm d-btn-error text-white"
							@click="
								rysqer.auth.logout().then(() => {
									rysqer.router.push({ name: 'login' })
									rysqer.libs.toast.success('Bye bye and thank you!')
								})
							"
							v-if="rysqer.auth.authenticated"
						>
							Logout
						</button>
					</div>
				</tippy>
			</div>
		</div>

		<!-- ------------------------------------------------- -->
		<!-- MAIN -->
		<!-- ------------------------------------------------- -->
		<div class="w-full relative LAYOUT_MAIN" :class="[appHeightClass]">
			<slot></slot>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, inject } from 'vue'

const rysqer = inject('rysqer')

const actionbar = ref(false)

const appHeightClass = computed(() => {
	return 'rysqer-layout--app-height'
})
</script>

<style lang="scss">
:root {
	--rysqer-layout--navigationbar-height: 4rem;
	--rysqer-layout--actionbar-height: 2rem;
}

.rysqer-layout--navigationbar-height {
	height: var(--rysqer-layout--navigationbar-height);
}

.rysqer-layout--app-height {
	height: calc(100vh - var(--rysqer-layout--navigationbar-height));
}
</style>
