import { useBpmnStore } from '@/stores/bpmn'
import { append as svgAppend, attr as svgAttr, create as svgCreate } from 'tiny-svg'
import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer'

const HIGH_PRIORITY = 1500
const TASK_BORDER_RADIUS = 0

const COLOR_WHITE = '#FFFFFF'
const COLOR_INDIGO = '#570DF8'

// https://github.com/bpmn-io/bpmn-js-example-custom-rendering
// https://github.com/bpmn-io/tiny-svg

export default class CustomRenderer extends BaseRenderer {
	constructor(eventBus, bpmnRenderer) {
		super(eventBus, HIGH_PRIORITY)
		this.bpmnRenderer = bpmnRenderer
	}

	canRender(element) {
		return !element.labelTarget
	}

	drawShape(parentNode, element) {
		const type = element.type
		const shape = this.bpmnRenderer.drawShape(parentNode, element)
		const typeIsTask = type.indexOf('Task') > 0

		if (typeIsTask) {
			const diagram = useBpmnStore().diagram
			const activity = diagram.activities.find((a) => a.identifier === element.id)
			const identifiers = activity ? activity.controls.map((c) => c.identifier) : []

			if (identifiers.length) {
				svgAttr(shape, { stroke: COLOR_INDIGO, strokeWidth: 3 })

				const initialHeight = 6
				const heightIncrease = 15
				let identifierHeight = initialHeight

				identifiers.forEach((controlIdentifier) => {
					// ---------------------------------------------------
					// Control symbol rect
					// ---------------------------------------------------
					let identifierRectHeight = identifierHeight + 8.5
					const rect = drawRect(parentNode, 10, 10, TASK_BORDER_RADIUS, COLOR_INDIGO)
					svgAttr(rect, {
						transform: 'translate(0, -' + identifierRectHeight + ')',
					})

					// ---------------------------------------------------
					// Control symbol text
					// ---------------------------------------------------
					var c = svgCreate('text')
					svgAttr(c, {
						style: 'font: bold 10px sans-serif;',
						fill: COLOR_WHITE,
						transform: 'translate(1.2, -' + identifierHeight + ')',
					})

					svgAppend(c, document.createTextNode('C'))
					svgAppend(parentNode, c)

					// ---------------------------------------------------
					// Control identifier
					// ---------------------------------------------------
					var identifier = svgCreate('text')
					svgAttr(identifier, {
						style: 'font: bold 10px sans-serif;',
						fill: COLOR_INDIGO,
						transform: 'translate(15, -' + identifierHeight + ')',
					})

					svgAppend(identifier, document.createTextNode(controlIdentifier))
					svgAppend(parentNode, identifier)

					identifierHeight = identifierHeight + heightIncrease
				})
			}
		}

		return shape
	}

	getShapePath(shape) {
		return this.bpmnRenderer.getShapePath(shape)
	}
}

CustomRenderer.$inject = ['eventBus', 'bpmnRenderer']

function drawRect(parentNode, width, height, borderRadius, color) {
	const rect = svgCreate('rect')

	svgAttr(rect, {
		width: width,
		height: height,
		rx: borderRadius,
		ry: borderRadius,
		stroke: color,
		strokeWidth: 2,
		fill: color,
	})

	svgAppend(parentNode, rect)

	return rect
}

function drawCircle(parentNode, diameter, borderRadius, color) {
	const circle = svgCreate('circle')

	svgAttr(circle, {
		r: diameter,
		fill: color,
	})

	svgAppend(parentNode, circle)

	return circle
}
