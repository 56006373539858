import { useBpmnStore } from '@/stores/bpmn'
import { is, getBusinessObject } from 'bpmn-js/lib/util/ModelUtil'

export default class CustomContextPad {
	constructor(bpmnFactory, config, contextPad, create, elementFactory, injector, translate, modeling) {
		this.bpmnFactory = bpmnFactory
		this.create = create
		this.elementFactory = elementFactory
		this.translate = translate
		this._modeling = modeling

		if (config.autoPlace !== false) {
			this.autoPlace = injector.get('autoPlace', false)
		}

		contextPad.registerProvider(this)
	}

	getContextPadEntries(element) {
		const modeling = this._modeling
		const businessObject = getBusinessObject(element)
		const bpmnStore = useBpmnStore()

		return function (entries) {
			if (!is(element, 'bpmn:Task')) return entries

			delete entries['delete']

			entries['delete'] = {
				group: 'edit',
				className: 'bpmn-icon-trash',
				title: 'Remove',
				action: {
					click: function (event) {
						const canDelete = bpmnStore.handleDeleteIntegrityCheck(element.id)
						if (canDelete) {
							modeling.removeElements([element])
						}
					},
				},
			}

			if (businessObject && businessObject.name) {
				entries['details'] = {
					group: 'rysqer',
					className: 'rysqer-icon rysqer-icon-c',
					title: 'Add controls',
					action: {
						click: function (event) {
							bpmnStore.handleElementDetailRequest(element)
						},
					},
				}
			}

			return entries
		}
	}
}

CustomContextPad.$inject = [
	'bpmnFactory',
	'config',
	'contextPad',
	'create',
	'elementFactory',
	'injector',
	'translate',
	'modeling',
]
