import CustomPalette from './CustomPalette'
import CustomContextPad from './CustomContextPad'
import CustomRenderer from './CustomRenderer'
import CustomPopupProvider from './CustomPopupProvider'

export default {
	__init__: ['customPalette', 'customRenderer', 'customContextPad', 'CustomPopupProvider'],
	customPalette: ['type', CustomPalette],
	customContextPad: ['type', CustomContextPad],
	customRenderer: ['type', CustomRenderer],
	CustomPopupProvider: ['type', CustomPopupProvider],
}
