import VueRouter from 'vue-router'
import routes from './routes.js'
import { useAuthStore } from '@/stores/auth'
import { useStageStore } from '@/stores/stage'

const router = new VueRouter({
	routes,
})

router.beforeEach((to, from, next) => {
	const authStore = useAuthStore()

	const hasPermissionsRequirement = (route) => route.meta.permissions && route.meta.permissions.length > 0

	const requiresAuth = to.matched.some(hasPermissionsRequirement)

	if (!requiresAuth) {
		return next()
	}

	if (!authStore.authenticated) {
		return next({ name: 'login', query: { redirect: to.fullPath } })
	}

	const isAuthorized = to.matched.some((route) => {
		if (!hasPermissionsRequirement(route)) return true

		if (route.meta.permissions.includes('*')) return true

		return route.meta.permissions.some((permission) => authStore.check(permission))
	})

	if (!isAuthorized) {
		return next({ name: 'not-authorized' })
	}

	return next()
})

router.afterEach((to) => {
	const authStore = useAuthStore()
	const stageStore = useStageStore()

	stageStore.takeStageFromUser()

	if (authStore.authenticated) {
		if (to.path.startsWith('/auth/') || to.path === '/' || to.path === '*') {
			return
		}

		const currentRoutes = authStore.profile.get('last.routes') || []

		if (Array.isArray(currentRoutes) && currentRoutes.length > 0 && currentRoutes[0] === to.name) {
			return
		}

		const filteredRoutes = Array.isArray(currentRoutes)
			? currentRoutes.filter((routeName) => routeName !== to.name)
			: []

		const newRoutes = [to.name, ...filteredRoutes]

		const limitedRoutes = newRoutes.slice(0, 10)

		authStore.profile.sync('last.routes', limitedRoutes)
	}
})
export default router
