<template>
	<img v-if="source" :src="source" :alt="alt" class="openmoji" :style="style" />
	<span v-else>{{ fallbackCharacter }}</span>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'

const props = defineProps({
	// Can be either emoji character ("😀") or hex code ("1F600")
	emoji: {
		type: String,
		required: true,
	},
	// Optional alt text for accessibility
	alt: {
		type: String,
		default: '',
	},
	// Optional width - if not provided, CSS can be used
	width: {
		type: [String, Number],
		default: null,
	},
	// Optional height - if not provided, CSS can be used
	height: {
		type: [String, Number],
		default: null,
	},
	// Color or black variant
	variant: {
		type: String,
		default: 'color',
		validator: (value) => ['color', 'black'].includes(value),
	},
	// Base path for self-hosted OpenMoji SVG files
	basePath: {
		type: String,
		default: '/assets/openmoji',
	},
})

// Store the original emoji character
const fallbackCharacter = ref(props.emoji)

// Convert emoji character to hex code - moved to computed property to avoid reactivity issues
const hexCode = computed(() => {
	try {
		// Handle case where input is already a hex code
		if (/^[0-9A-F]{4,}$/i.test(props.emoji)) {
			return props.emoji.toUpperCase()
		}

		// Convert emoji character to hex code
		return Array.from(props.emoji)
			.map((char) => char.codePointAt(0).toString(16).toUpperCase())
			.join('-')
	} catch (e) {
		console.error('Error converting emoji to hex:', e)
		return null
	}
})

// Construct the URL for the emoji SVG
const source = computed(() => {
	if (!hexCode.value) return null
	return `${props.basePath}/${props.variant}/svg/${hexCode.value}.svg`
})

// Dynamically set width and height if provided
const style = computed(() => {
	const result = {}
	if (props.width) result.width = typeof props.width === 'number' ? `${props.width}px` : props.width
	if (props.height) result.height = typeof props.height === 'number' ? `${props.height}px` : props.height
	return result
})
</script>

<style scoped>
.openmoji {
	display: inline-block;
	vertical-align: middle;
	width: 1.5em;
	height: 1.5em;
}
</style>
