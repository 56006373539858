import Vue from 'vue'
import App from './App.vue'

// ------------------------------------------------
// Pinia
// ------------------------------------------------
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

// ------------------------------------------------
// Pinia Persisted Store
// ------------------------------------------------
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
pinia.use(piniaPluginPersistedstate)

// ------------------------------------------------
// Libs & Axios (before router)
// ------------------------------------------------
import axios from '@/libs/axios'
import '../tailwind-vue.config.js'

// ------------------------------------------------
// Router
// ------------------------------------------------
import router from './router/index.js'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// ------------------------------------------------
// Portal Vue
// ------------------------------------------------
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// ------------------------------------------------
// OpenMoji Component - Lazy loaded to prevent infinite refresh
// ------------------------------------------------
// Defer the component registration until Vue is initialized
const registerOpenMoji = () => {
	const OpenMoji = require('@/components/OpenMoji.vue').default
	Vue.component('open-moji', OpenMoji)
}

// ------------------------------------------------
// Broadcast
// ------------------------------------------------
// import { useBroadcastChannel } from '@vueuse/core'
// Vue.use(useBroadcastChannel)

// ------------------------------------------------
// Vueform
// ------------------------------------------------
import Vueform from '@vueform/vueform'
import vueformConfig from '../vueform.config'

Vue.use(Vueform, vueformConfig)

// ------------------------------------------------
// SCSS
// ------------------------------------------------
import '../tailwind.scss'

// ------------------------------------------------
// BROADCASTING
// ------------------------------------------------
const initializeBroadcasting = () => {
	import('pusher-js').then((Pusher) => {
		window.Pusher = Pusher.default
		import('laravel-echo').then((Echo) => {
			window.Echo = new Echo.default({
				broadcaster: 'pusher',
				key: process.env.VUE_APP_PUSHER_APP_KEY,
				cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				forceTLS: true,
				authorizer: (channel) => ({
					authorize: (socketId, callback) => {
						axios
							.post(process.env.VUE_APP_PUSHER_AUTH_HOST, {
								socket_id: socketId,
								channel_name: channel.name,
							})
							.then((response) => callback(false, response.data))
							.catch((error) => callback(true, error))
					},
				}),
			})
		})
	})
}

// ------------------------------------------------
// TIPPY
// ------------------------------------------------
import VueTippy, { TippyComponent } from 'vue-tippy'
import 'tippy.js/themes/light.css'

Vue.use(VueTippy, {
	inertia: true,
	interactive: true,
	arrow: false,
	animateFill: false,
	placement: 'bottom',
	distance: 7,
	theme: 'light',
	animation: 'shift-away',
	trigger: 'click',
	maxWidth: '100%',
})
Vue.component('tippy', TippyComponent)

// ------------------------------------------------
// VUE TOASTIFICATION
// ------------------------------------------------
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

Vue.use(Toast, {
	position: 'bottom-right',
	timeout: 2000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: 'button',
	icon: true,
	rtl: false,
	transition: 'Vue-Toastification__fade',
	maxToasts: 20,
	newestOnTop: true,
})

// ------------------------------------------------
// GLOBAL COMPONENTS
// ------------------------------------------------
const registerGlobalComponents = () => {
	Vue.component('icon', () => import('@/components/Icon'))
	Vue.component('rysqer-list', () => import('@/components/List/List'))
	Vue.component('rysqer-more', () => import('@/components/More'))
	Vue.component('rysqer-form', () => import('@/components/Form'))
	Vue.component('rysqer-info', () => import('@/components/Info'))
	Vue.component('rysqer-views', () => import('@/layout/Views'))
}

// ------------------------------------------------
// VUE
// ------------------------------------------------
Vue.config.productionTip = false

const initializeVue = () => {
	registerGlobalComponents()
	registerOpenMoji()
	initializeBroadcasting()

	new Vue({
		pinia,
		router,
		render: (h) => h(App),
	}).$mount('#app')
}

initializeVue()
