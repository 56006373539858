const routes = [
	{
		path: '*',
		name: '404',
		component: () => import('@/apps/auth/Login.vue'),
		meta: {
			name: 'Page Not Found',
		},
	},

	/* --------------------------------------------------- */
	/* AUTH */
	/* --------------------------------------------------- */
	{
		path: '/auth/login',
		name: 'login',
		meta: {
			name: 'Login',
		},
		component: () => import('@/apps/auth/Login.vue'),
	},

	{
		path: '/auth/not-authorized',
		name: 'not-authorized',
		meta: {
			name: 'Not Authorized',
		},
		component: () => import('@/apps/auth/NotAuthorized.vue'),
	},

	{
		path: '/auth/forgot-password',
		name: 'forgot-password',
		meta: {
			name: 'Forgot Password',
		},
		component: () => import('@/apps/auth/ForgotPassword.vue'),
	},

	{
		path: '/auth/reset-link-sent',
		name: 'reset-link-sent',
		meta: {
			name: 'Reset Link Sent',
		},
		component: () => import('@/apps/auth/ResetLinkSent.vue'),
	},

	{
		path: '/auth/reset-password',
		name: 'reset-password',
		meta: {
			name: 'Reset Password',
		},
		component: () => import('@/apps/auth/ResetPassword.vue'),
	},

	/* --------------------------------------------------- */
	/* HOME */
	/* --------------------------------------------------- */
	{
		path: '/',
		name: 'home',
		component: () => import('@/apps/home/Home.vue'),
		props: { view: 'dashboard', scene: 'apps' },
		meta: {
			name: 'Home Dashboard',
			permissions: ['*'],
		},
	},

	/* --------------------------------------------------- */
	/* ICS */
	/* --------------------------------------------------- */
	{
		path: '/ics',
		name: 'ics-default',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			name: 'Interal Control System Tasks',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'test.apps.ics.app', 'use.apps.ics.app'],
		},
	},

	{
		path: '/ics/tasks',
		name: 'ics-tasks',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			name: 'Interal Control System Tasks',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'test.apps.ics.app', 'use.apps.ics.app'],
		},
	},

	{
		path: '/ics/tasks/:uuid',
		name: 'ics-tasks-resource',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			name: 'Interal Control System Tasks',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'test.apps.ics.app', 'use.apps.ics.app'],
		},
	},

	{
		path: '/ics/processes',
		name: 'ics-processes',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'processes', scene: 'processes' },
		meta: {
			name: 'Processes',
			permissions: ['*'],
		},
	},

	{
		path: '/ics/processes/entities',
		name: 'ics-processes-entities',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'processes', scene: 'entities' },
		meta: {
			permissions: ['*'],
		},
	},

	{
		path: '/ics/controls',
		name: 'ics-controls',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'controls' },
		meta: {
			name: 'ICS Controls',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app', 'use.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator',
		name: 'ics-coordinator',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'dashboard' },
		meta: {
			name: 'ICS Coordinator Dashboard',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator/rcm',
		name: 'ics-coordinator-rcm',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'rcm' },
		meta: {
			name: 'ICS Coordinator RCM',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator/runs',
		name: 'ics-coordinator-runs',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'control_runs' },
		meta: {
			name: 'ICS Coordinator Control Runs',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator/tickets',
		name: 'ics-coordinator-tickets',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'tickets' },
		meta: {
			name: 'ICS Coordinator Tickets',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator/assessments',
		name: 'ics-coordinator-assessments',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'control_assessments' },
		meta: {
			name: 'ICS Coordinator Assessments',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/coordinator/users',
		name: 'ics-coordinator-users',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'coordinator', scene: 'users' },
		meta: {
			name: 'ICS Coordinator Users',
			permissions: ['admin.apps.ics.app', 'manage.apps.ics.app'],
		},
	},

	{
		path: '/ics/tester',
		name: 'ics-tester',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester', scene: 'dashboard' },
		meta: {
			name: 'ICS Tester Dashboard',
			permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
		},
	},

	{
		path: '/ics/tester/rcm',
		name: 'ics-tester-rcm',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester', scene: 'rcm' },
		meta: {
			name: 'ICS Tester RCM',
			permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
		},
	},

	{
		path: '/ics/tester/runs',
		name: 'ics-tester-runs',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester', scene: 'test_runs' },
		meta: {
			name: 'ICS Tester Test Runs',
			permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
		},
	},

	{
		path: '/ics/tester/walkthroughs',
		name: 'ics-tester-walkthroughs',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester', scene: 'walkthrough_runs' },
		meta: {
			name: 'ICS Tester Walkthroughs',
			permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
		},
	},

	{
		path: '/ics/tester/schedules',
		name: 'ics-tester-schedules',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester', scene: 'testing_schedules' },
		meta: {
			name: 'ICS Tester Schedules',
			permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
		},
	},

	{
		path: '/ics/tester/tickets',
		name: 'ics-tester-tickets',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester', scene: 'tickets' },
		meta: {
			name: 'ICS Tester Tickets',
			permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
		},
	},

	{
		path: '/ics/tester/assessments',
		name: 'ics-tester-assessments',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'tester', scene: 'control_assessments' },
		meta: {
			name: 'ICS Tester Assessments',
			permissions: ['admin.apps.ics.app', 'test.apps.ics.app'],
		},
	},

	{
		path: '/ics/auditor',
		name: 'ics-auditor',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'auditor', scene: 'rcm' },
		meta: {
			name: 'ICS Auditor Dashboard',
			permissions: ['admin.apps.ics.app', 'audit.apps.ics.app'],
		},
	},

	{
		path: '/ics/auditor/rcm',
		name: 'ics-auditor-rcm',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'auditor', scene: 'rcm' },
		meta: {
			name: 'ICS Auditor RCM',
			permissions: ['admin.apps.ics.app', 'audit.apps.ics.app'],
		},
	},

	{
		path: '/ics/auditor/runs',
		name: 'ics-auditor-runs',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'auditor', scene: 'control_runs' },
		meta: {
			name: 'ICS Auditor Control Runs',
			permissions: ['admin.apps.ics.app', 'audit.apps.ics.app'],
		},
	},

	{
		path: '/ics/auditor/assessments',
		name: 'ics-auditor-assessments',
		component: () => import('@/apps/ics/Home.vue'),
		props: { view: 'auditor', scene: 'control_assessments' },
		meta: {
			name: 'ICS Auditor Assessments',
			permissions: ['admin.apps.ics.app', 'audit.apps.ics.app'],
		},
	},

	/* --------------------------------------------------- */
	/* RMS */
	/* --------------------------------------------------- */

	{
		path: '/rms',
		name: 'rms-default',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			name: 'Risk Management Tasks',
			permissions: ['admin.apps.rms.app', 'manage.apps.rms.app', 'use.apps.rms.app'],
		},
	},

	{
		path: '/rms/tasks',
		name: 'rms-tasks',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			name: 'Risk Management Tasks',
			permissions: ['admin.apps.rms.app', 'manage.apps.rms.app', 'use.apps.rms.app'],
		},
	},

	{
		path: '/rms/tasks/:uuid',
		name: 'rms-tasks-resource',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			name: 'Risk Management Tasks',
			permissions: ['admin.apps.rms.app', 'manage.apps.rms.app', 'use.apps.rms.app'],
		},
	},

	{
		path: '/rms/registers',
		name: 'rms-registers',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'registers' },
		meta: {
			name: 'Risk Management Registers',
			permissions: ['admin.apps.rms.app', 'manage.apps.rms.app', 'use.apps.rms.app'],
		},
	},

	{
		path: '/rms/heatmap',
		name: 'rms-heatmap',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'heatmap' },
		meta: {
			name: 'Risk Management Heatmap',
			permissions: ['admin.apps.rms.app', 'manage.apps.rms.app', 'use.apps.rms.app'],
		},
	},

	{
		path: '/rms/admin',
		name: 'rms-admin',
		component: () => import('@/apps/rms/Home.vue'),
		props: { view: 'admin' },
		meta: {
			name: 'Risk Management Admin',
			permissions: ['admin.apps.rms.app', 'manage.apps.rms.app'],
		},
	},

	/* --------------------------------------------------- */
	/* ESG */
	/* --------------------------------------------------- */

	{
		path: '/esg',
		name: 'esg-default',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'dashboard' },
		meta: {
			name: 'ESG Dashboard',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/dashboard',
		name: 'esg-dashboard',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'dashboard' },
		meta: {
			name: 'ESG Dashboard',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/materiality',
		name: 'esg-materiality',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'materiality' },
		meta: {
			name: 'ESG Materiality',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/data',
		name: 'esg-data',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'data' },
		meta: {
			name: 'ESG Data Points',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/calculators/dashboard',
		name: 'esg-calculators-dashboard',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'calculators', scene: 'dashboard' },
		meta: {
			name: 'ESG Calculators',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/calculators',
		name: 'esg-calculators',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'calculators', scene: 'dashboard' },
		meta: {
			name: 'ESG Calculators',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/calculators/energy',
		name: 'esg-calculators-energy',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'calculators', scene: 'energy' },
		meta: {
			name: 'ESG Energy Calculator',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/calculators/water',
		name: 'esg-calculators-water',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'calculators', scene: 'water' },
		meta: {
			name: 'ESG Water Calculator',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/calculators/waste',
		name: 'esg-calculators-waste',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'calculators', scene: 'waste' },
		meta: {
			name: 'ESG Waste Calculator',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app', 'use.apps.esg.app'],
		},
	},

	{
		path: '/esg/reporting',
		name: 'esg-reporting',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'reporting' },
		meta: {
			name: 'ESG Reporting',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app'],
		},
	},

	{
		path: '/esg/admin',
		name: 'esg-admin',
		component: () => import('@/apps/esg/Home.vue'),
		props: { view: 'admin' },
		meta: {
			name: 'ESG Administration',
			permissions: ['admin.apps.esg.app', 'manage.apps.esg.app'],
		},
	},

	/* --------------------------------------------------- */
	/* CYB */
	/* --------------------------------------------------- */

	{
		path: '/cyb',
		name: 'cyb-admin',
		component: () => import('@/apps/cyb/Home.vue'),
		props: { view: 'admin', scene: 'dashboard' },
		meta: {
			name: 'IT-Security Admin',
			permissions: ['admin.apps.cyb.app', 'manage.apps.cyb.app', 'use.apps.cyb.app'],
		},
	},

	{
		path: '/cyb/admin/dashboard',
		name: 'cyb-admin-dashboard',
		component: () => import('@/apps/cyb/Home.vue'),
		props: { view: 'admin', scene: 'dashboard' },
		meta: {
			name: 'IT-Security Dashboard',
			permissions: ['admin.apps.cyb.app', 'manage.apps.cyb.app', 'use.apps.cyb.app'],
		},
	},

	{
		path: '/cyb/admin/inventory',
		name: 'cyb-admin-inventory',
		component: () => import('@/apps/cyb/Home.vue'),
		props: { view: 'admin', scene: 'inventory' },
		meta: {
			name: 'IT-Security Asset Inventory',
			permissions: ['admin.apps.cyb.app', 'manage.apps.cyb.app', 'use.apps.cyb.app'],
		},
	},

	{
		path: '/cyb/tasks',
		name: 'cyb-tasks',
		component: () => import('@/apps/cyb/Home.vue'),
		props: { view: 'tasks' },
		meta: {
			name: 'IT-Security Tasks',
			permissions: ['admin.apps.cyb.app', 'manage.apps.cyb.app', 'use.apps.cyb.app'],
		},
	},
]
export default routes
