import { defineStore } from 'pinia'
import { ref, reactive, getCurrentInstance } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export const useStageStore = defineStore('rysqer-stage-store', () => {
	const vm = getCurrentInstance()

	const stages = ref([])

	const sizes = ref([])

	const modalScene = ref(null)

	const isModalOpen = ref(false)

	const userOwnsStage = ref(false)

	const isResizing = ref(false)

	const createStage = (scene, position, resources, options) => {
		scene.resources = resources
		scene.options = options

		stages.value.push(
			reactive({
				uuid: uuidv4(),
				position: position,

				hero: {
					title: null,
					supertitle: null,
					supertitleClass: null,
					subtitle: null,
					subtitleClass: null,
					description: null,
					desctionClass: null,
					class: null,
					buttons: null,
					menu: null,
					badges: [],
				},

				setHero: setHero,
				resetHero: resetHero,

				scenes: [scene],
				scene: scene,
				title: null,
				options: options,

				init: init,
				get: get,

				put: put,
				unput: unput,

				pop: pop,
				unpop: unpop,

				tab: tab,
				untab: untab,
				retab: retab,

				resize: resize,
				rsize: rsize,

				isOn: isOn,
				isSize: isSize,
				isSized: isSized,

				clear: clear,
				clearFrom: clearFrom,

				busy: false,
				setBusy: setBusy,

				userOwnsStage: userOwnsStage,
				giveStageToUser: giveStageToUser,
				takeStageFromUser: takeStageFromUser,
			})
		)
	}

	const get = (position) => {
		if (position <= stages.value.length) return stages.value[position]
		else return null
	}

	const setHero = (position, hero) => {
		resetHero(position)
		if (position < stages.value.length) stages.value[position].hero = hero
	}

	const setBusy = (position, busy) => {
		stages.value[position].busy = busy
	}

	const resetHero = (position) => {
		if (position < stages.value.length)
			stages.value[position].hero = {
				title: null,
				supertitle: null,
				supertitleClass: null,
				subtitle: null,
				subtitleClass: null,
				description: null,
				desctionClass: null,
				class: null,
				buttons: null,
				menu: null,
				badges: [],
			}
	}

	const init = () => {
		stages.value.splice(0)
		sizes.value.splice(0)
		userOwnsStage.value = false
	}

	const put = (scene, position, resources = {}, options = {}) => {
		if (position >= stages.value.length) {
			createStage(scene, position, resources, options)
		} else {
			let theScene = { ...scene, resources: resources, options: options }
			stages.value[position].scene = { ...theScene }
			stages.value[position].scenes = [{ ...theScene }]
			stages.value[position].options = options
		}
	}

	const unput = (position, subsequent = false) => {
		if (position <= stages.value.length) {
			if (subsequent) stages.value.splice(position)
			else stages.value.splice(position, 1)

			stages.value.forEach((stage, i) => (stage.position = i))
		}
	}

	const pop = (scene, resources = {}, options = {}) => {
		// Use the scene directly without wrapping in reactive
		scene.resources = resources || scene.resources || {}
		scene.options = { ...(scene.options || {}), ...options, isModal: true }

		// Set the modalScene reference to the existing scene
		modalScene.value = scene
		isModalOpen.value = true
	}

	const unpop = () => {
		modalScene.value = null
		isModalOpen.value = false
	}

	const tab = (scene, position, resources, label, options = {}) => {
		scene.label = label ?? scene.id

		if (position >= stages.value.length) {
			createStage(scene, position, resources, options)
		}

		const existingScene = stages.value[position].scenes.find((s) => s.label === label)

		if (existingScene) {
			stages.value[position].scene = existingScene
			stages.value[position].scene.resources = resources
			stages.value[position].scene.options = options
		} else {
			let theScene = { ...scene, resources: resources, options: options }
			stages.value[position].scenes.push(theScene)
			stages.value[position].scene = theScene
		}
	}

	const untab = (id, position) => {
		const sceneIndex = stages.value[position].scenes.findIndex((s) => s.id === id)

		if (sceneIndex !== -1) {
			const previousSceneId = stages.value[position].scenes[sceneIndex].options?.tabs?.previous.id
			const previousSceneIndex = stages.value[position].scenes.findIndex((s) => s.id === previousSceneId)

			stages.value[position].scenes.splice(sceneIndex, 1)

			if (previousSceneIndex !== -1) {
				stages.value[position].scene = stages.value[position].scenes[previousSceneIndex]
			} else if (stages.value[position].scenes.length >= sceneIndex - 1) {
				stages.value[position].scene = stages.value[position].scenes[sceneIndex - 1]
			}
		}
	}

	const retab = (position, label) => {
		stages.value[position].scene = stages.value[position].scenes.find((s) => s.label === label)
	}

	const resize = (theSizes) => {
		vm.proxy.$nextTick(() => {
			sizes.value.splice(0, 999, ...theSizes)
		})
	}

	const proportional = (s) => {
		const base = s.reduce((accum, curr) => accum + curr, 0)
		return s.map((theSize) => {
			const relation = theSize > 0 ? (theSize * 100) / base : 0
			return parseFloat(relation.toFixed(2))
		})
	}

	const rsize = (s, fromResizeHandler = false) => {
		// If this is a direct resize from the handler, always apply it
		if (fromResizeHandler) {
			const theSizes = proportional(s)
			resize(theSizes)
			return
		}

		// Otherwise, respect user customizations for programmatic updates
		if (userOwnsStage.value) {
			const newSizes = [...sizes.value]

			// Only update sizes that are explicitly set to 0 or for new panes
			s.forEach((size, index) => {
				if (index >= sizes.value.length || size === 0 || sizes.value[index] === 0) {
					newSizes[index] = size
				}
			})

			const theSizes = proportional(newSizes)
			resize(theSizes)
		} else {
			// Original behavior
			const theSizes = proportional(s)
			resize(theSizes)
		}
	}

	const giveStageToUser = () => {
		userOwnsStage.value = true
	}

	const takeStageFromUser = () => {
		userOwnsStage.value = false
	}

	const isSize = (position, size) => {
		if (sizes.value.length < position) return false

		return sizes.value[position] == size
	}

	const isSized = (position) => {
		if (sizes.value.length < position) return false
		return sizes.value[position] > 0
	}

	const isOn = (scene, position = 0) => {
		if (stages.value.length > 0 && stages.value.length >= position) return stages.value[position]?.scene.id == scene.id
		else return false
	}

	const clear = (position) => {
		if (position < stages.value.length) {
			stages.value[position].scenes.splice(0)
			stages.value[position].scene = null
		}
	}

	const clearFrom = (position) => {
		for (let i = position; i < stages.value.length; i++) {
			clear(i)
		}
	}

	return {
		stages,
		sizes,
		modalScene,
		isModalOpen,
		userOwnsStage,
		giveStageToUser,
		takeStageFromUser,
		isResizing,
		init,
		get,
		put,
		unput,
		pop,
		unpop,
		tab,
		untab,
		retab,
		resize,
		rsize,
		isSize,
		isSized,
		isOn,
		clear,
		clearFrom,

		setHero,
		resetHero,
	}
})
