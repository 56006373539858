import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/libs/axios'

export const useOptionsStore = defineStore('rysqer-options-store', () => {
	const hydrating = ref(false)

	const users = ref([])

	const controls = ref([])
	const subscribedControls = ref([])

	const currentYear = ref()
	const years = ref([])

	const currentFiscalYear = ref()
	const fiscalYears = ref([])

	const currentQuarter = ref()
	const quarters = ref([])

	const currentFiscalQuarter = ref()
	const fiscalQuarters = ref([])

	const processes = ref([])
	const countries = ref([])

	const systems = ref([])

	const frequencies = ref([])

	// const riskCategories = ref([])

	// const riskSubCategories = ref([])

	const riskPrograms = ref([])

	const currentRiskRegister = ref()
	const riskRegisters = ref([])

	const currentRiskCycle = ref()
	const riskCycles = ref([])

	const appLevelConfig = ref([])

	const loadUsers = async () => {
		return axios.get(`/options/users`).then(({ data }) => {
			users.value = data
			Promise.resolve()
		})
	}

	const getUsernameByUuid = (uuid) => {
		if (users.value?.length) {
			return users.value.find((u) => u.uuid === uuid)?.name
		}
	}

	const loadControls = async () => {
		return axios.get(`/options/controls`).then(({ data }) => {
			controls.value = data
			Promise.resolve()
		})
	}

	const loadSubscribedControls = async () => {
		return axios.get(`/options/controls/?subscribed=true`).then(({ data }) => {
			subscribedControls.value = data
			Promise.resolve()
		})
	}

	const loadYears = async () => {
		return axios.get(`/options/years`).then(({ data }) => {
			years.value = data.all
			currentYear.value = data.current
			Promise.resolve()
		})
	}

	const loadQuarters = async () => {
		return axios.get(`/options/quarters`).then(({ data }) => {
			quarters.value = data.all
			currentQuarter.value = data.current
			Promise.resolve()
		})
	}

	const loadFiscalYears = async () => {
		return axios.get(`/options/fiscal-years`).then(({ data }) => {
			fiscalYears.value = data.all
			currentFiscalYear.value = data.current
			Promise.resolve()
		})
	}

	const loadFiscalQuarters = async () => {
		return axios.get(`/options/fiscal-quarters`).then(({ data }) => {
			fiscalQuarters.value = data.all
			currentFiscalQuarter.value = data.current
			Promise.resolve()
		})
	}

	const loadCountries = async () => {
		return axios.get(`/options/countries`).then(({ data }) => {
			countries.value = data
			Promise.resolve()
		})
	}

	const loadProcesses = async () => {
		return axios.get(`/options/processes/?character=all`).then(({ data }) => {
			processes.value = [...new Set(data.map((process) => process.name))].sort()
			Promise.resolve()
		})
	}

	const loadSystems = async () => {
		return axios.get(`/options/systems`).then(({ data }) => {
			systems.value = data
			Promise.resolve()
		})
	}

	const loadFrequencies = async () => {
		return axios.get(`/options/frequencies`).then(({ data }) => {
			frequencies.value = data
			Promise.resolve()
		})
	}

	const loadRiskPrograms = async () => {
		return axios.get(`/risks/options/programs`).then(({ data }) => {
			riskPrograms.value = data
			Promise.resolve()
		})
	}

	const loadRiskRegisters = async () => {
		return axios.get(`/risks/options/registers`).then(({ data }) => {
			riskRegisters.value = data.all
			currentRiskRegister.value = data.current
			Promise.resolve()
		})
	}

	const loadRiskCycles = async () => {
		return axios.get(`/risks/options/cycles`).then(({ data }) => {
			riskCycles.value = data.all
			currentRiskCycle.value = data.current
			Promise.resolve()
		})
	}

	const loadAppLevelConfig = async () => {
		return axios.get(`/options/config/app-level`).then(({ data }) => {
			appLevelConfig.value = data
		})
	}

	const hydrate = () => {
		hydrating.value = true

		Promise.all([
			loadUsers(),
			loadFiscalYears(),
			loadFiscalQuarters(),
			loadCountries(),
			loadProcesses(),
			loadSystems(),
			loadFrequencies(),
			loadRiskPrograms(),
			loadRiskCycles(),
			loadRiskRegisters(),
			loadAppLevelConfig(),
		]).finally(() => {
			hydrating.value = false
		})
	}

	return {
		hydrating,
		hydrate,

		users,
		controls,
		subscribedControls,
		currentYear,
		years,
		currentFiscalYear,
		fiscalYears,
		currentQuarter,
		quarters,
		currentFiscalQuarter,
		fiscalQuarters,
		countries,
		processes,
		systems,
		frequencies,

		riskPrograms,
		riskRegisters,
		currentRiskRegister,
		riskCycles,
		currentRiskCycle,

		appLevelConfig,

		loadUsers,
		loadControls,
		loadSubscribedControls,
		loadYears,
		loadQuarters,
		loadFiscalYears,
		loadFiscalQuarters,
		loadCountries,
		loadProcesses,
		loadSystems,
		loadFrequencies,

		loadRiskPrograms,
		loadRiskCycles,
		loadRiskRegisters,

		loadAppLevelConfig,

		getUsernameByUuid,
	}
})
