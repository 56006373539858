import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

import BpmnModeler from 'bpmn-js/lib/Modeler'
import BpmnViewer from 'bpmn-js/lib/Viewer'

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil'

import '../modules/Bpmnio/libs/custom.scss'
import modeler from '../modules/Bpmnio/libs/modeler'
import viewer from '../modules/Bpmnio/libs/viewer'
import resizeTask from 'bpmn-js-task-resize/lib'

import keyboardMove from 'diagram-js/lib/navigation/keyboard-move'
import movecanvas from 'diagram-js/lib/navigation/movecanvas'
import zoomscroll from 'diagram-js/lib/navigation/zoomscroll'

import BpmnColorPickerModule from 'bpmn-js-color-picker'

import { clickToDownload, b64Decode, b64Encode } from '@/libs/functions'
import axios from '@/libs/axios'

export const useBpmnStore = defineStore('rysqer-bpmn-store', () => {
	const viewerModel = ref(null)
	const modelerModel = ref(null)
	const modelerInitialized = ref(false)

	const model = ref(null)
	const canvas = ref(null)
	const element = ref(null)
	const businessObject = ref(null)
	const zoomFactor = ref(1)

	const readonly = ref(true)
	const inline = ref(false)
	const resizeable = ref(false)
	const editable = ref(false)
	const dirty = ref(false)

	const previousActivityName = ref(null)
	const newActivityName = ref(null)
	const activity = ref(null)

	// Timeout holder
	let _fitOriginalTimeout = null

	// ===== Callbacks =====
	const onIntegrityWarning = ref(() => {})
	const onElementDetail = ref(() => {})

	// ===== Activity Creation =====
	const createActivity = () => {
		return reactive({
			identifier: null,
			name: null,
			controls: [],
			persisted: false,
			form: {
				controls: {
					controls: [],
				},
			},

			init(activity) {
				this.identifier = activity.identifier
				this.name = activity.name
				this.controls = activity.controls
				this.persisted = activity.persisted

				this.form = {
					controls: {
						controls: activity.controls ? activity.controls.map((c) => c.uuid) : [],
					},
				}

				return this
			},

			async load(identifier = null) {
				const { data } = await axios.get(
					`/processes/diagrams/${diagram.uuid}/activities/${identifier || this.identifier}`
				)

				this.init(data)
				return Promise.resolve(this)
			},

			async create() {
				const { data } = await axios.post(`/processes/diagrams/${diagram.uuid}/activities`, {
					identifier: this.identifier,
					name: this.name,
				})

				this.init(data)
			},

			async delete(activity) {
				await axios.delete(`/processes/diagrams/${diagram.uuid}/activities`, { data: activity })
			},

			async assign(controls) {
				await axios.patch(`/processes/diagrams/${diagram.uuid}/activities/assign`, {
					identifier: this.identifier,
					name: this.name,
					controls: controls,
				})

				this.load()
			},
		})
	}

	// ===== Diagram Object =====
	const diagram = reactive({
		uuid: null,
		name: null,
		index: null,
		xml: null,
		binary: null,
		process: {
			uuid: null,
			name: null,
			entities: [],
			country: null,
			locale: null,
		},
		activities: [],
		has: {
			controls: null,
		},
		form: {},
		timestamp: null,

		get hash() {
			return this.uuid ? this.uuid + '-' + this.timestamp : null
		},

		createActivity() {
			return createActivity()
		},

		init(diagramData) {
			this.uuid = diagramData.uuid
			this.name = diagramData.name
			this.index = diagramData.index
			this.xml = diagramData.xml
			this.binary = diagramData.binary
			this.process = diagramData.process
			this.activities = diagramData.activities
			this.has = diagramData.has
			this.timestamp = new Date()
		},

		async load(uuid = null) {
			const { data } = await axios.get(`/processes/diagrams/${uuid || this.uuid}`)

			this.init(data)
			this.binary = data.xml
			this.xml = b64Decode(data.xml)

			await importXML(this.xml)
		},

		async create(process, name) {
			let xml = `<?xml version="1.0" encoding="UTF-8"?>
			<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_1peeh32" targetNamespace="http://bpmn.io/schema/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="8.9.0">
			  <bpmn:process id="Process_19i8lab" isExecutable="false">
				<bpmn:startEvent id="StartEvent_00ewlt7" />
			  </bpmn:process>
			  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
				<bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_19i8lab">
				</bpmndi:BPMNPlane>
			  </bpmndi:BPMNDiagram>
			</bpmn:definitions>
			`

			let binary = b64Encode(xml)
			const { data } = await axios.post(`/processes/${process.uuid}/diagrams`, { name: name, xml: binary })

			this.init(data)
			this.xml = xml
			this.binary = binary

			await importXML(this.xml)
		},

		async update() {
			const xml = await exportXML()
			const binary = b64Encode(xml)

			const formData = new FormData()
			formData.append('xml', binary)

			await axios.post(`/processes/diagrams/${this.uuid}/definition`, formData)

			// Update dirty flag directly
			dirty.value = false

			await this.load()
		},

		async updateActivityName(identifier, name) {
			await axios.patch(`/processes/diagrams/${this.uuid}/activities/${identifier}/name`, { identifier, name })
			return Promise.resolve(true)
		},

		async rename(name) {
			await axios.patch(`/processes/diagrams/${this.uuid}/name`, { name: name })
			this.name = name
		},

		async copy(name) {
			await axios.patch(`/processes/diagrams/${this.uuid}/copy`, { name: name })
		},

		async move(uuid) {
			await axios.patch(`/processes/diagrams/${this.uuid}/move`, { uuid: uuid })
		},

		async delete() {
			await axios.delete(`/processes/diagrams/${this.uuid}`)
		},
	})

	// Initialize models
	const bindViewer = () => {
		if (!viewerModel.value) {
			viewerModel.value = new BpmnViewer({
				container: '#bpmnViewer',
				additionalModules: [viewer, keyboardMove, movecanvas, zoomscroll],
			})

			viewerModel.value.on('canvas.viewbox.changed', 1500, (event) => {
				if (model.value === viewerModel.value) {
					zoomFactor.value = canvas.value.zoom() || 1
				}
			})
		}
	}

	const bindModeler = () => {
		if (!modelerModel.value) {
			modelerModel.value = new BpmnModeler({
				container: '#bpmnModeler',
				additionalModules: [modeler, resizeTask, keyboardMove, movecanvas, zoomscroll, BpmnColorPickerModule],
				taskResizingEnabled: true,
			})

			modelerModel.value.on('element.click', 1500, (event) => {
				element.value = event.element
				businessObject.value = getBusinessObject(event.element)
			})

			modelerModel.value.on('element.dblclick', 1500, (event) => {
				previousActivityName.value = businessObject.value.name
			})

			modelerModel.value.on('element.changed', 1500, (event) => {
				element.value = event.element
				businessObject.value = getBusinessObject(event.element)
				newActivityName.value = businessObject.value.name

				if (
					previousActivityName.value &&
					newActivityName.value &&
					previousActivityName.value !== newActivityName.value
				) {
					handleElementNameChange(element.value.id, newActivityName.value)
				} else {
					dirty.value = true
				}
			})

			modelerModel.value.on('canvas.viewbox.changed', 1500, (event) => {
				if (model.value === modelerModel.value) {
					zoomFactor.value = canvas.value.zoom() || 1
				}
			})

			modelerInitialized.value = true
		}
	}

	const setActiveModel = () => {
		model.value = readonly.value ? viewerModel.value : modelerModel.value
		if (model.value) {
			canvas.value = model.value.get('canvas')
		}
	}

	const initializeViewer = () => {
		bindViewer()
		setActiveModel()
		importCurrentDiagram()
	}

	const initializeModeler = () => {
		if (!modelerInitialized.value) {
			bindModeler()
		}
		setActiveModel()
		importCurrentDiagram()
	}

	const handleModeChange = (isReadOnly) => {
		readonly.value = isReadOnly

		if (!isReadOnly && !modelerInitialized.value) {
			bindModeler()
			modelerInitialized.value = true
		}

		setActiveModel()

		return importCurrentDiagram()
	}

	// ===== XML Import/Export Methods =====
	const importCurrentDiagram = async () => {
		if (!diagram.xml) return

		if (!readonly.value && !modelerInitialized.value) {
			bindModeler()
			modelerInitialized.value = true
			setActiveModel()
		}

		await importXML(diagram.xml)
		// centerDiagram()
	}

	const importXML = async (xml) => {
		if (!model.value) return

		try {
			const result = await model.value.importXML(xml)
			// fitOriginal()
			return result
		} catch (err) {
			console.log(err.message, err.warnings)
		}
	}

	const exportXML = async () => {
		if (!model.value) return

		try {
			const { xml } = await model.value.saveXML({ format: true })
			return xml
		} catch (err) {
			console.log(err.message, err.warnings)
		}
	}

	// ===== Element Interaction Methods =====
	const handleElementNameChange = (identifier, name) => {
		diagram.updateActivityName(identifier, name)
		exportXML().then((xml) => {
			diagram.update(xml)
			dirty.value = false
		})
		previousActivityName.value = null
	}

	const handleDeleteIntegrityCheck = (elementId) => {
		const foundActivity = diagram.activities.find((a) => a.identifier === elementId)
		const hasControls = foundActivity && foundActivity.controls.length > 0

		if (hasControls) {
			onIntegrityWarning.value('This activity has controls assigned.')
			return false
		}

		return true
	}

	const handleElementDetailRequest = async (elementObj) => {
		if (!elementObj) return

		element.value = elementObj
		businessObject.value = getBusinessObject(elementObj)

		activity.value = diagram.createActivity()

		try {
			await activity.value.load(elementObj.id)
			activity.value.persisted = true
		} catch {
			activity.value.identifier = elementObj.id
			activity.value.name = businessObject.value.name
			activity.value.controls = []
			activity.value.persisted = false
			activity.value.form.controls = {
				controls: [],
			}
		}

		onElementDetail.value()

		return activity.value
	}

	// ===== Zoom Methods =====
	const zoomIn = () => {
		if (!model.value) return
		model.value.get('zoomScroll').stepZoom(1)
		zoomFactor.value = canvas.value.zoom() || 1
	}

	const zoomOut = () => {
		if (!model.value) return
		model.value.get('zoomScroll').stepZoom(-1)
		zoomFactor.value = canvas.value.zoom() || 1
	}

	const zoomTo = (id, zoomLevel = 1) => {
		if (!model.value) return
		let el = model.value.get('elementRegistry').get(id)

		if (el) {
			canvas.value.scrollToElement(el)
			model.value.get('selection').select(el)
			canvas.value.zoom(zoomLevel, el)
			zoomFactor.value = canvas.value.zoom() || 1
		}
	}

	const fitScreen = () => {
		if (!model.value) return
		model.value.get('zoomScroll').reset()
		zoomFactor.value = canvas.value.zoom() || 1
	}

	const fitOriginal = () => {
		if (!model.value || !canvas.value) return

		if (_fitOriginalTimeout) {
			clearTimeout(_fitOriginalTimeout)
		}

		canvas.value.zoom(1.0)
		zoomFactor.value = 1.0

		_fitOriginalTimeout = setTimeout(() => {
			const elementRegistry = model.value.get('elementRegistry')
			const allElements = elementRegistry.getAll().filter((el) => el.type.includes('bpmn:'))

			if (allElements.length === 0) return

			canvas.value.viewbox({
				x: 0,
				y: 0,
				width: 800,
				height: 600,
			})

			canvas.value.zoom(1.0)
			zoomFactor.value = 1.0

			_fitOriginalTimeout = null
		}, 100)
	}

	// ===== Command Stack Methods =====
	const undo = () => {
		if (!model.value) return
		model.value.get('commandStack').undo()
	}

	const redo = () => {
		if (!model.value) return
		model.value.get('commandStack').redo()
	}

	// ===== SVG Export Methods =====
	const getSvgAsBlob = async () => {
		if (!model.value) return
		const { error, svg } = await model.value.saveSVG()
		if (error) {
			console.log(error)
		}

		let blob = new Blob([svg], {
			type: 'image/svg+xml',
		})

		return blob
	}

	const getSvgAsString = async () => {
		if (!model.value) return
		const { error, svg } = await model.value.saveSVG()
		if (error) console.log(error)

		return svg
	}

	const saveAsSvg = async (name) => {
		if (!model.value) return
		try {
			const { svg } = await model.value.saveSVG()
			const filename = name + '.svg'
			clickToDownload(svg, filename)
		} catch (err) {
			console.error('Error saving svg: ', err)
		}
	}

	const centerDiagram = () => {
		if (!model.value || !canvas.value) return

		canvas.value.zoom(1.0)

		setTimeout(() => {
			const elementRegistry = model.value.get('elementRegistry')
			const startEvent = elementRegistry.getAll().find((el) => el.type === 'bpmn:StartEvent')

			if (startEvent) {
				canvas.value.scrollToElement(startEvent, { top: 100, bottom: 100, left: 100, right: 100 })
			} else {
				const anyElement = elementRegistry.getAll().find((el) => el.type.includes('bpmn:'))
				if (anyElement) {
					canvas.value.scrollToElement(anyElement, { top: 100, bottom: 100, left: 100, right: 100 })
				} else {
					canvas.value.viewbox({ x: 0, y: 0, width: 500, height: 500 })
				}
			}

			zoomFactor.value = canvas.value.zoom() || 1
		}, 50)
	}

	const init = () => {
		dirty.value = false
		readonly.value = true
		inline.value = false
		resizeable.value = false
		editable.value = false

		previousActivityName.value = null
		newActivityName.value = null
		activity.value = null
		element.value = null
		businessObject.value = null
		zoomFactor.value = 1
	}

	// Explicit dirty state setter for cross-component usage
	const setDirty = (value) => {
		dirty.value = value
	}

	// Return all properties and methods directly
	return {
		// Properties
		dirty,
		viewerModel,
		modelerModel,
		modelerInitialized,
		model,
		canvas,
		element,
		businessObject,
		zoomFactor,
		readonly,
		inline,
		resizeable,
		editable,
		previousActivityName,
		newActivityName,
		activity,
		diagram,

		// Callbacks
		onIntegrityWarning,
		onElementDetail,

		// Methods
		createActivity,
		bindViewer,
		bindModeler,
		setActiveModel,
		initializeViewer,
		initializeModeler,
		handleModeChange,
		importCurrentDiagram,
		importXML,
		exportXML,
		handleElementNameChange,
		handleDeleteIntegrityCheck,
		handleElementDetailRequest,
		zoomIn,
		zoomOut,
		zoomTo,
		fitScreen,
		fitOriginal,
		undo,
		redo,
		getSvgAsBlob,
		getSvgAsString,
		saveAsSvg,
		centerDiagram,
		init,
		setDirty,
	}
})
