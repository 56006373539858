export default class CustomPalette {
	constructor(bpmnFactory, create, elementFactory, palette, translate) {
		this.bpmnFactory = bpmnFactory
		this.create = create
		this.elementFactory = elementFactory
		this.translate = translate

		palette.registerProvider(this)
	}

	getPaletteEntries(element) {
		return {
			/*
			undo: {
				group: 'utils',
				className: 'rysqer-icon rysqer-icon-undo',
				title: 'Undo',
				action: {
					click: function(event) {
						window.dispatchEvent(new CustomEvent('rysqer-palette-undo', { details: event }))
					},
				},
			},
			redo: {
				group: 'utils',
				className: 'rysqer-icon rysqer-icon-redo',
				title: 'Redo',
				action: {
					click: function(event) {
						window.dispatchEvent(new CustomEvent('rysqer-palette-redo', { details: event }))
					},
				},
			},
			zoomIn: {
				group: 'utils',
				className: 'rysqer-icon rysqer-icon-zoom-in',
				title: 'Zoom In',
				action: {
					click: function(event) {
						window.dispatchEvent(new CustomEvent('rysqer-palette-zoom-in', { details: event }))
					},
				},
			},
			zoomOut: {
				group: 'utils',
				className: 'rysqer-icon rysqer-icon-zoom-out',
				title: 'Zoom Out',
				action: {
					click: function(event) {
						window.dispatchEvent(new CustomEvent('rysqer-palette-zoom-out', { details: event }))
					},
				},
			},
			fitScreen: {
				group: 'utils',
				className: 'rysqer-icon rysqer-icon-fit-screen',
				title: 'Fit Screen',
				action: {
					click: function(event) {
						window.dispatchEvent(new CustomEvent('rysqer-palette-fit-screen', { details: event }))
					},
				},
			},
			fitOriginal: {
				group: 'utils',
				className: 'rysqer-icon rysqer-icon-fit-original',
				title: 'Fit 100%',
				action: {
					click: function(event) {
						window.dispatchEvent(new CustomEvent('rysqer-palette-fit-original', { details: event }))
					},
				},
			},
			*/

			/*
			The entries above fire event which can be consumed elsewhere, i. e.:

			window.addEventListener('rysqer-palette-undo', (event) => {
				undo()
			})


			*/
		}
	}
}

CustomPalette.$inject = ['bpmnFactory', 'create', 'elementFactory', 'palette', 'translate']
