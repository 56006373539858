import { defineStore } from 'pinia'
import { ref, reactive, computed } from 'vue'
import { useOptionsStore } from '@/stores/options'
import axios from '@/libs/axios'
import { get as _get, set as _set, merge as _merge } from 'lodash-es'

export const useAuthStore = defineStore(
	'rysqer-auth-store',
	() => {
		const user = ref({})
		const can = reactive({})

		const authenticated = computed(() => user.value.authenticated)

		const resetUser = () => {
			user.value = {
				uuid: null,
				name: null,
				initials: null,
				title: null,
				active: false,
				email: null,
				can: {},
				tenant: null,
				apps: null,
				profile: null,
				last: {
					routes: [],
				},

				authenticated: false,
			}
		}

		const check = (can) => {
			return !!_get(user.value.can, can, false)
		}

		const login = async (credentials) => {
			await axios.get('/csrf-cookie')
			await axios.post('/login', credentials)

			try {
				const { data } = await axios.get('me')

				Object.assign(can, data.can)
				user.value = data
				user.value.authenticated = true

				useOptionsStore().hydrate()
			} catch (error) {
				resetUser()
			}
		}

		const logout = async () => {
			await axios.post('/logout')
			resetUser()
		}

		const forgotPassword = async (credentials) => {
			await axios.get('/csrf-cookie')
			await axios.post('/forgot-password', credentials)
		}

		const resetPassword = async (credentials) => {
			await axios.get('/csrf-cookie')
			await axios.post('/reset-password', credentials)
		}

		const profile = reactive({
			sync: async (path, value) => {
				if (!user.value.profile) {
					user.value.profile = {}
				}

				const newProfile = { ...user.value.profile }
				_set(newProfile, path, value)
				user.value.profile = newProfile

				await axios.patch(`/users/${user.value.uuid}/profile`, user.value.profile)

				persist()
			},

			get: (path = null) => {
				return path ? _get(user.value.profile, path) : user.value.profile
			},
		})

		function persist() {
			const store = useAuthStore()
			store.$persist()
		}

		return {
			user,
			authenticated,
			can,
			check,

			resetUser,
			login,
			logout,
			forgotPassword,
			resetPassword,

			profile,
			persist,
		}
	},
	{
		persist: {
			key: 'rysqer',
		},
	}
)
