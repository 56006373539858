import { defineStore } from 'pinia'
import { ref } from 'vue'
import { get as _get, set as _set } from 'lodash-es'

export const useTempStore = defineStore('rysqer-temp-store', () => {
	const data = ref({})

	const sync = (path, value) => {
		if (!data.value) {
			data.value = {}
		}

		const newData = { ...data.value }
		_set(newData, path, value)
		data.value = newData
	}

	const get = (path = null) => {
		return _get(data.value, path, null)
	}

	const has = (path) => {
		const value = get(path)

		if (!value) return false

		return Object.entries(value).some((entry) => {
			if (Array.isArray(entry[1])) return entry[1].length > 0
			else return !!entry[1]
		})
	}

	const reset = () => {
		data.value = {}
	}

	return {
		data,
		sync,
		get,
		has,
		reset,
	}
})
